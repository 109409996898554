/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const t = /^https:\/\/([a-z\d-]+)(\.maps([^.]*))?\.arcgis\.com/i,
  e = {
    devext: {
      customBaseUrl: "mapsdevext.arcgis.com",
      portalHostname: "devext.arcgis.com"
    },
    qaext: {
      customBaseUrl: "mapsqa.arcgis.com",
      portalHostname: "qaext.arcgis.com"
    },
    www: {
      customBaseUrl: "maps.arcgis.com",
      portalHostname: "www.arcgis.com"
    }
  };
function s(s) {
  const a = s?.match(t);
  if (!a) return null;
  const [, r, c, o] = a;
  if (!r) return null;
  let l = null,
    m = null,
    n = null;
  const {
    devext: u,
    qaext: i,
    www: p
  } = e;
  if (c) {
    if (l = r, o) switch (o.toLowerCase()) {
      case "devext":
        ({
          customBaseUrl: m,
          portalHostname: n
        } = u);
        break;
      case "qa":
        ({
          customBaseUrl: m,
          portalHostname: n
        } = i);
        break;
      default:
        return null;
    } else ({
      customBaseUrl: m,
      portalHostname: n
    } = p);
  } else switch (r.toLowerCase()) {
    case "devext":
      ({
        customBaseUrl: m,
        portalHostname: n
      } = u);
      break;
    case "qaext":
      ({
        customBaseUrl: m,
        portalHostname: n
      } = i);
      break;
    case "www":
      ({
        customBaseUrl: m,
        portalHostname: n
      } = p);
      break;
    default:
      return null;
  }
  return {
    customBaseUrl: m,
    isPortal: !1,
    portalHostname: n,
    urlKey: l
  };
}
function a(t) {
  return /\/(sharing|usrsvcs)\/(appservices|servers)\//i.test(t);
}
function r(t) {
  const e = /^https?:\/\/(?:cdn|[a-z\d-]+\.maps)\.arcgis\.com/i,
    s = /^https?:\/\/(?:cdndev|[a-z\d-]+\.mapsdevext)\.arcgis\.com/i,
    a = /^https?:\/\/(?:cdnqa|[a-z\d-]+\.mapsqa)\.arcgis\.com/i;
  return e.test(t) ? t = t.replace(e, "https://www.arcgis.com") : s.test(t) ? t = t.replace(s, "https://devext.arcgis.com") : a.test(t) && (t = t.replace(a, "https://qaext.arcgis.com")), t;
}
export { a as isSecureProxyService, r as normalizeArcGISOnlineOrgDomain, s as parseKnownArcGISOnlineDomain, t as reArcGISOnlineDomain };